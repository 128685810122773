import { useSelector } from "react-redux";
import { AesDecrypt } from "../../helpers/AesCrypto";
import { ControlTokenValidity } from "../../helpers/ControlTokenValidity";
import { Logout } from "../../services/AppServices";
import { EmailOwnerImpersonate } from "../../store/feautures/ImpersonateSlice";
import { CurrentInfoUser, IsAutenticate } from "../../store/feautures/SetAuthenticatedUserSlice";

export default function useAuth() {

    let authState = AesDecrypt(useSelector(IsAutenticate));
    let userInfo: null | any = useSelector(CurrentInfoUser);
    let { token }: null | any = useSelector(EmailOwnerImpersonate);
    let isAutenticated = false;

    if (authState) GetSession();

    async function GetSession() {
        try {
            isAutenticated = ControlTokenValidity(userInfo?.token || token)
        }
        catch (e) {
            Logout()
        }
    }

    return isAutenticated;

}