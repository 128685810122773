import jwtDecode from "jwt-decode";


export function ControlTokenValidity(token: any) {

    let isValidToken: boolean

    let decodedToken: any = jwtDecode(token);
    let currentDate: Date = new Date()
    if (decodedToken?.exp * 1000 < currentDate.getTime()) {
        isValidToken = false
    } else {
        isValidToken = true
    }

    return isValidToken
}