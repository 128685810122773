import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";

export interface ITheme {
    active: boolean,
    theme: string | null
}

const setThemeClass = createSlice({
    name: 'SET_THEME',
    initialState: {
        active: false,
        theme: ''
    },

    reducers: {
        setTheme: (state, {payload}: PayloadAction<any>) => {
            return {...payload};
        }
    }
    
})

export const { setTheme } = setThemeClass.actions;

//EXPORT SELECTRO FOR CHILD
export const ThemeClass = (state: RootState) => state.setThemeClass;

export default setThemeClass.reducer