import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_END_POINT ? process.env.REACT_APP_END_POINT : '';
export const axiosPublicInterceptor = axios.create();

axiosPublicInterceptor.interceptors.request.use(async (config) => {
  return config
}, (error) => {
  return Promise.reject(error);
});

axiosPublicInterceptor.interceptors.response.use((response) => {
  if (response.status === 200) {
    return Promise.resolve(response.data)
  }
}, (error) => {
  return Promise.reject(error?.response?.data);
});