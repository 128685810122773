import { axiosInterceptor } from "../interceptors/AppInterceptor";
import { axiosPublicInterceptor } from "../interceptors/PublicInterceptor";
import { InitialValueImpersonate, setEmailImpersonate } from "../store/feautures/ImpersonateSlice";
import { store } from "../store/Store";

export async function Login(data: any) {
    return axiosInterceptor.post(`/api/v1/auth/login`, data)
}

export async function Logout() {
    store.dispatch(setEmailImpersonate(InitialValueImpersonate))
    return axiosInterceptor.post(`/api/v1/auth/logout`)
}

export async function ChangePassword(dataNewPassword: {password: string, newPassword: string}) {
    return axiosInterceptor.post(`/api/v1/auth/change-password`, dataNewPassword)
}

export async function ResetPassword(token: string, password: string) {
    return axiosPublicInterceptor.post(`/api/v1/auth/reset`, password, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export async function SentEmailPassword(email: string) {
    return axiosPublicInterceptor.post(`/api/v1/auth/forgot`, email)
}

export async function Impersonate(email: string) {
    return axiosInterceptor.post(`/api/v1/auth/impersonate`, email)
}