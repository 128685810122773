import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AllExceptOwner, OnlyAdmin, OnlyOwner, OwnerAdmin, UserAdmin } from '../utils/lists/RoleUserList';
import PrivateRouterGuard from './guard/PrivateRouterGuard';
import PublicRouterGuard from './guard/PublicRouterGuard';
import { RoleTypeGuard } from './guard/RoleTypeGuard';
import DashboardPage from '../pages/dashboard/DashboardPage';


const LoginPage = lazy(() => import('../pages/LoginPage'));
const Dashboard = lazy(() => import('../pages/Home'));
const SettingsMe = lazy(() => import('../pages/SettingsMe'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage'));

// USERS
const UsersMain = lazy(() => import('../pages/users/UsersMainPage'));
const UsersListPage = lazy(() => import('../pages/users/UsersListPage'));

// SETTINGS
const MainSettingsPage = lazy(() => import('../pages/settings/MainSettingsPage'));
const SettingsPage = lazy(() => import('../pages/settings/SettingPage'));

//CONSUMERS
const MainPageConsumers = lazy(() => import('../pages/consumers/MainPageConsumers'));
const ConsumersPage = lazy(() => import('../pages/consumers/ConsumersPage'));

//RECIPIENTS
const RecipientsMainPage = lazy(() => import('../pages/recipients/RecipientsMainPage'));
const RecipientsPage = lazy(() => import('../pages/recipients/RecipientsPage'));

//FILTERS
const MainPageFilters = lazy(() => import('../pages/filters/FiltersMainPage'));
const FiltersPage = lazy(() => import('../pages/filters/FiltersPage'));

//MESSAGES
const MainPageMessages = lazy(() => import('../pages/messages/MessagesListPage'));
const MessagesPage = lazy(() => import('../pages/messages/MesagesMainPage'));

//TEMPLATES
const MainPageTemplates = lazy(() => import('../pages/templates/MainPageTemplates'));
const TemplatesPage = lazy(() => import('../pages/templates/TemplatesPage'));

export default function RoutesList() {
    return useRoutes([
        {
            path: '/',
            element:
                <PublicRouterGuard>
                    <LoginPage />
                </PublicRouterGuard>
        },
        {
            path: '/reset',
            element:
                <PublicRouterGuard>
                    <ForgotPasswordPage />
                </PublicRouterGuard>
        },
        {
            path: '/activation',
            element:
                <PublicRouterGuard>
                    <ForgotPasswordPage />
                </PublicRouterGuard>
        },
        {
            path: 'home',
            element:
                <PrivateRouterGuard>
                    <Dashboard />
                </PrivateRouterGuard>,
            children: [
                {
                    path: '/home',
                    element: <DashboardPage />,
                },
                {
                    path: 'me',
                    element:
                        <SettingsMe />
                },
                {
                    path: 'campaigns',
                    element: <MainPageMessages />,
                    children: [
                        {
                            path: '/home/campaigns',
                            element: <RoleTypeGuard visibilityFor={AllExceptOwner}><MessagesPage /></RoleTypeGuard>,
                        }
                    ]
                },
                {
                    path: 'models',
                    element: <RoleTypeGuard visibilityFor={OnlyOwner} >
                        <MainPageTemplates />
                    </RoleTypeGuard>,
                    children: [
                        {
                            path: '/home/models',
                            element: <TemplatesPage />,
                        }
                    ]
                },/*
                {
                    path: 'audience',
                    element:
                        <div>AUDIENCE</div>
                },*/
                {
                    path: 'filters',
                    element: <MainPageFilters />,
                    children: [
                        {
                            path: '/home/filters',
                            element: <RoleTypeGuard visibilityFor={UserAdmin}><FiltersPage /></RoleTypeGuard>,
                        }
                    ]
                },
                {
                    path: 'users',
                    element: <RoleTypeGuard visibilityFor={OwnerAdmin} >
                        <UsersMain />
                    </RoleTypeGuard>,
                    children: [
                        {
                            path: '/home/users',
                            element: <UsersListPage />,
                        }
                    ]
                },
                {
                    path: 'settings',
                    element: <MainSettingsPage />,
                    children: [
                        {
                            path: '/home/settings',
                            element: <RoleTypeGuard visibilityFor={OnlyAdmin} ><SettingsPage /></RoleTypeGuard>,
                        }
                    ]
                },
                {
                    path: 'consumers',
                    element: <RoleTypeGuard visibilityFor={OnlyOwner} >
                        <MainPageConsumers />
                    </RoleTypeGuard>,
                    children: [
                        {
                            path: '/home/consumers',
                            element: <ConsumersPage />,
                        }
                    ]
                },
                {
                    path: 'recipients',
                    element: <RecipientsMainPage />,
                    children: [
                        {
                            path: '/home/recipients',
                            element: <RoleTypeGuard visibilityFor={UserAdmin}><RecipientsPage /></RoleTypeGuard>,
                        }
                    ]
                },
                {
                    path: 'notifications',
                    element:
                        <div>NoTIFICHE</div>
                },
            ]

        },
        { path: '*', element: <Navigate to='/' replace /> }
    ])
}