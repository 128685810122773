import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateTemplate, DeleteTemplate, GetTemplateById, GetTemplate, UpdateTemplate } from "../../services/TemplatesService";
import { IUser } from "../../utils/models/UsersModels";
import { RootState } from "../Store";

export const getListTemplates: any = createAsyncThunk(
  "templates/getListTemplates",
  async (data: any,thunkAPI: any) => {
    try 
    {
      return await GetTemplate(data?.params, data?.paramsHeader)
    }
    catch(err: any) {
        console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getTemplateById: any = createAsyncThunk(
    "templates/getTemplateById",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await GetTemplateById(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const updateTemplate: any = createAsyncThunk(
    "templates/updateTemplate",
    async (dataForm: {id: string, data: IUser}, thunkAPI: any) => {
      try 
      {
        return await UpdateTemplate(dataForm.id, dataForm.data)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const createTemplate: any = createAsyncThunk(
    "templates/createTemplate",
    async (dataForm: any, thunkAPI: any) => {
      try 
      {
        return await CreateTemplate(dataForm)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const deleteTemplate: any = createAsyncThunk(
    "templates/deleteTemplate",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await DeleteTemplate(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );


const setCrudTemplates = createSlice({
    name: 'SET_CRUD_TEMPLATES',
    initialState: {
        statusGet: 'idl',
        statusGetById: 'idl',
        statusCreate: 'idl',
        statusUpdate: 'idl',
        statusDelete: 'idl',
        currentTemplate: null
    },
  
    reducers: {},
  
    extraReducers: {
      [getListTemplates.pending]: (state: any) => {
        state.statusGet = 'loading';
      },
      [getListTemplates.fulfilled]: (state: any) => {
        state.statusGet = 'success';
      },
      [getListTemplates.rejected]: (state: any) => {
        state.statusGet = 'failed';
      },

      [getTemplateById.pending]: (state: any) => {
        state.statusGetById = 'loading';
      },
      [getTemplateById.fulfilled]: (state: any, {payload}: any) => {
        state.statusGetById = 'success';
        state.currentTemplate = {...payload};
      },
      [getTemplateById.rejected]: (state: any) => {
        state.statusGetById = 'failed';
        state.currentTemplate = null;
      },

      [updateTemplate.pending]: (state: any) => {
        state.statusUpdate = 'loading';
      },
      [updateTemplate.fulfilled]: (state: any) => {
        state.statusUpdate = 'success';
      },
      [updateTemplate.rejected]: (state: any) => {
        state.statusUpdate = 'failed';
      },

      [createTemplate.pending]: (state: any) => {
        state.statusCreate = 'loading';
      },
      [createTemplate.fulfilled]: (state: any) => {
        state.statusCreate = 'success';
      },
      [createTemplate.rejected]: (state: any) => {
        state.statusCreate = 'failed';
      },

      [deleteTemplate.pending]: (state: any) => {
        state.statusDelete = 'loading';
      },
      [deleteTemplate.fulfilled]: (state: any) => {
        state.statusDelete = 'success';
      },
      [deleteTemplate.rejected]: (state: any) => {
        state.statusDelete = 'failed';
      },

    },
  })

//EXPORT SELECTRO FOR CHILD
export const StateCrudTemplates = (state: RootState) => state.setCrudTemplates;

export default setCrudTemplates.reducer