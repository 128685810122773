import { axiosInterceptor } from "../interceptors/AppInterceptor";

export async function GetFilters(params: any, paramsHeader: any) {
    const queryString = params ? `?name=${params}` : '' 
    return axiosInterceptor.get(`/api/v1/filters${queryString}`, {headers: {...paramsHeader}})
}

export async function GetFilterById(id: string) {
    return axiosInterceptor.get(`/api/v1/filters/${id}`)
}

export async function UpdateFilter(id: string, dataForm: any) {
    return axiosInterceptor.patch(`/api/v1/filters/${id}`, dataForm)
}

export async function CreateFilter(dataForm: any) {
    return axiosInterceptor.post(`/api/v1/filters`, dataForm)
}

export async function DeleteFilter(id: string) {
    return axiosInterceptor.delete(`/api/v1/filters/${id}`)
}

export async function ExecuteFilter(dataForm: any) {
    return axiosInterceptor.post(`/api/v1/filters/execute`, dataForm)
}