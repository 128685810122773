import moment from "moment";
import * as echarts from 'echarts';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import { ChatIcon, MailIcon, BellIcon } from "@heroicons/react/outline";

const getWeekNumbers = (month: any, year: any) => {
    let first, last, weeks=[];
    first = moment().month(month - 1).year(year).startOf('month').isoWeek();
    last = moment().month(month - 1).year(year).endOf('month').isoWeek();
    
    for(let i = first; i <= last; i++){
      weeks.push(i + 1);
    }
    return weeks;
}

export const getStylesCircle = {
    SMS: {
        stroke: 'rgb(167, 85, 246)',
        icon: <ChatIcon className="w-7 h-7 text-purple-500" />
    },
    MAIL: {
        stroke: 'rgb(59, 130, 245)',
        icon: <MailIcon className="w-7 h-7 text-blue-500" />
    },
    MOBILEPUSH: {
        stroke: 'rgb(249, 115, 22)',
        icon: <BellIcon className="w-7 h-7 text-orange-500" />
    }
}

const getGradient = (color: string) => {
    let gradientMap: any = {
        orange: ['rgba(249, 115, 22, 0.7)', 'rgba(249, 115, 22, 0.3)'],
        blue: ['rgba(59, 130, 245, 0.7)', 'rgba(59, 130, 245, 0.3)'],
        purple: ['rgba(167, 85, 246, 0.7)', 'rgba(167, 85, 246, 0.3)']
    }
    return {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
                offset: 0,
                color: gradientMap[color][0]
            },
            {
                offset: 1,
                color: gradientMap[color][1]
            }
        ])
    }
}

export const ChartFilter: any = (year: number, type: string) => {
    return {
        ANNUAL: {
            from_date: moment().year(year).startOf('year').format('YYYY-MM-DD'),
            to_date: moment().year(year).endOf('month').format('YYYY-MM-DD')
        },
        MONTHLY: {
            from_date: moment().year(year).startOf('month').format('YYYY-MM-DD'),
            to_date: moment().year(year).endOf('month').format('YYYY-MM-DD')
        },
        WEEKLY: {
            from_date: moment().year(year).startOf('week').format('YYYY-MM-DD'),
            to_date: moment().year(year).endOf('week').format('YYYY-MM-DD')
        }
    }[type]
}

export const ChartRange = (type: string, language: string): any => {
    if (type === "ANNUAL") return moment.localeData(language).monthsShort().map(el => el.toUpperCase()).splice(0, moment().month() + 1);
    else if (type === "MONTHLY") return getWeekNumbers(moment().month(), moment().year());
    else if (type === "WEEKLY") return moment.localeData(language).weekdays().map(el => el.toUpperCase().substring(0, 3));
}

export const getDateRangeFromWeek = (weekNumber: any, year: any): string => {
    let beginningOfWeek = moment().isoWeekYear(year).isoWeek(weekNumber).startOf('week');
    let endOfWeek = moment().isoWeekYear(year).isoWeek(weekNumber).endOf('week');
    return `${beginningOfWeek.format('DD-MM')} / ${endOfWeek.format('DD-MM')}`;
}

export const ChartSeries: any[] = [
    {
        name: 'Sms',
        key: 'SMS',
        type: 'line',
        stack: 'Total',
        areaStyle: getGradient('purple'),
        emphasis: {
            focus: 'series'
        },
        data: []
    },
    {
        name: 'Email',
        key: 'MAIL',
        type: 'line',
        stack: 'Total',
        areaStyle: getGradient('blue'),
        emphasis: {
            focus: 'series'
        },
        data: []
    },
    {
        name: 'Mobile',
        key: 'MOBILEPUSH',
        type: 'line',
        stack: 'Total',
        areaStyle: getGradient('orange'),
        emphasis: {
            focus: 'series'
        },
        data: []
    }
];
