import { axiosInterceptor } from "../interceptors/AppInterceptor"
import { IAndroidConnector, IDeleteConnector, IEmailConnector, IHuaweiConnector, IIosConnector, ISmsConnector } from "../utils/models/ConnectorSettingsModel"

export async function GetConnector() {
    return axiosInterceptor.get('/api/v1/configurations/connector')
}

export async function CreateEmailConnector(dataEmail: IEmailConnector) {
    return axiosInterceptor.post('/api/v1/connectors/email', dataEmail)
}

export async function UpdateEmailConnector(id: string, dataEmail: IEmailConnector) {
    return axiosInterceptor.patch(`/api/v1/connectors/email/${id}`, dataEmail)
}

export async function CreateIosConnector(dataIos: IIosConnector) {
    return axiosInterceptor.post('/api/v1/connectors/mobile', dataIos)
}

export async function UpdateIosConnector(id: string, dataIos: IIosConnector) {
    return axiosInterceptor.patch(`/api/v1/connectors/mobile/${id}`, dataIos)
}

export async function CreateAndroidConnector(dataAndroid: IAndroidConnector) {
    return axiosInterceptor.post('/api/v1/connectors/mobile', dataAndroid)
}

export async function UpdateAndroidConnector(id: string, dataAndroid: IIosConnector) {
    return axiosInterceptor.patch(`/api/v1/connectors/mobile/${id}`, dataAndroid)
}

export async function CreateSmsConnector(dataSms: ISmsConnector) {
    return axiosInterceptor.post('/api/v1/connectors/sms', dataSms)
}

export async function UpdateSmsConnector(id: string, dataSms: ISmsConnector) {
    return axiosInterceptor.patch(`/api/v1/connectors/sms/${id}`, dataSms)
}

export async function GetActiveConnector(type: string) {
    return axiosInterceptor.get(`/api/v1/connectors/${type}/current`)
}

export async function SendConnectorEmail(dataConnector: any) {
    return axiosInterceptor.post('/api/v1/connectors/email/send', dataConnector)
}

export async function UploadFileConnector(dataConnector: any) {
    return axiosInterceptor.post('/api/v1/connectors/mobile/upload', dataConnector)
}

export async function CreateHuaweiConnector(dataHuawei: IHuaweiConnector) {
    return axiosInterceptor.post('/api/v1/connectors/mobile', dataHuawei)
}

export async function UpdateHuaweiConnector(id: string, dataHuawei: IHuaweiConnector) {
    return axiosInterceptor.patch(`/api/v1/connectors/mobile/${id}`, dataHuawei)
}

export async function DeleteConnector(dataDelete: IDeleteConnector | any) {
    return axiosInterceptor.delete('/api/v1/configurations/connector/delete', {data: dataDelete})
}