import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UploadFileConnector } from "../../services/SettingService";
import { RootState } from "../Store";

export const uploadFileConnectorMobile: any = createAsyncThunk(
    "connector/uploadFileConnector",
    async (dataFile: any, thunkAPI: any) => {
        try {
            return await UploadFileConnector(dataFile);
        }
        catch (err: any) {
            return thunkAPI.rejectWithValue({ error: err.message });
        }
    }
);

const initialValuesFile = {
    statusUploadFile: 'idl',
    fileConfig: null,
    isClose: false
}


const setFileConnector = createSlice({
    name: 'SET_UPLOAD_FILE_CONNECTOR',
    initialState: initialValuesFile,

    reducers: {
        resetFile: () => initialValuesFile
    },

    extraReducers: {

        [uploadFileConnectorMobile.pending]: (state: any) => {
            state.statusUploadFile = 'loading';
        },
        [uploadFileConnectorMobile.fulfilled]: (state: any, {payload}) => {
            state.statusUploadFile = 'success';
            state.fileConfig = {...payload.item}
        },
        [uploadFileConnectorMobile.rejected]: (state: any) => {
            state.statusUploadFile = 'failed';
            state.fileConfig = null
        },

    },
})

export const { resetFile } = setFileConnector.actions;

//EXPORT SELECTRO FOR CHILD
export const StateUploadFileConnector = (state: RootState) => state.setFileConnector;

export default setFileConnector.reducer