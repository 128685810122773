import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import CountUp from 'react-countup';
import { getStatsMessages } from "../../store/feautures/CrudMessageSlice";
import { getStatsRecipients } from "../../store/feautures/CrudRecipientSlice";
import ProgressBar from "../../components/shared/ProgressBar";
import InputsFields from "../../helpers/InputFields";
import StackAreaChart from "../../components/shared/StackAreaChart";
import { ChartRange, ChartFilter, ChartSeries, getDateRangeFromWeek, getStylesCircle } from "../../helpers/ChartHelper";
import { FormChartMessagesRange, FormChartMessagesYear } from "../../utils/inputList/FormChartMessages";
import { useTranslation } from "react-i18next";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Tooltip } from "../../components/shared/Tooltip";


let chartSelectedRange: string = 'WEEKLY';
let chartSelectedYear: string = FormChartMessagesYear('it')[0].options[0].value;

export default function DashboardPage() {
    const { i18n } = useTranslation();
    const language = i18n.language.slice(0, 2);
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            range_type: FormChartMessagesRange(language)[0].options.find((el: any) => el.value === chartSelectedRange),
            year: FormChartMessagesYear(language)[0].options[0]
        }
    });
    const { handleSubmit } = form;
    const [getStats, setStats] = useState<any>(null);
    const [getRecipients, setRecipients] = useState<any>(null);
    const [getChartInfo, setChartInfo] = useState<any>(JSON.stringify({
        categories: ChartRange(chartSelectedRange, language),
        series: []
    }));

    const getStatistics = async (data: any = null) => {
        try {
            const selectedRange = chartSelectedRange = data.range_type.value || data.range_type;
            const selectedYear = chartSelectedYear = data.year.value || data.year;
            const categories = ChartRange(chartSelectedRange, language);
            const options = { params: ChartFilter(selectedYear, selectedRange), paramsHeader: {} };
            const stats: any = await dispatch(getStatsMessages(options)).unwrap();
            if (stats) {
                for (let channel of ChartSeries) {
                    channel.data = [];
                    stats.items.forEach((el: any) => {
                        if (channel.key === el._id) {
                            channel.data = [...categories].map((x: any, index: any) => {
                                let find = el.dates.find((y: any) => {
                                    if (/MONTHLY/.test(selectedRange)) return y.week === x;
                                    else if (/WEEKLY/.test(selectedRange)) return y.dayOfWeek === index + 1;
                                    else if (/ANNUAL/.test(selectedRange)) return y.month === index + 1;
                                    else return null;
                                });
                                return find ? find.count : 0;
                            })
                        }
                    });
                }

                let categories_ = [...categories];
                if (/MONTHLY/.test(selectedRange)) {
                    categories_ = categories_.map((el: string): string => {
                        return getDateRangeFromWeek(el, selectedYear);
                    })
                }

                setStats(stats);
                setChartInfo(JSON.stringify({ categories: categories_, series: ChartSeries }));
            }
        } catch (rejectedValueOrSerializedError) {
        }
    }

    const getRecipientsStatistics = async () => {
        try {
            const options: any = { params: { active: true }, paramsHeader: {} };
            const recipients: any = await dispatch(getStatsRecipients(options)).unwrap();
            if (recipients) {
                setRecipients(recipients);
            }
        } catch (rejectedValueOrSerializedError) {
        }
    }

    const SubmitForm = async (data: any) => {
        getStatistics(data);
    }

    const getDataByChannel = (type: string, valueType: string, isRecipients?: boolean): number => {
        let total = isRecipients ? (getRecipients?.count ? getRecipients.count : 0) : (getStats?.count ? getStats.count : 0);
        let items = isRecipients ? (getRecipients?.items ? getRecipients.items : []) : (getStats?.items ? getStats.items : []);
        let item = items.find((el: any) => el._id === type);

        switch (valueType) {
            case 'percentage':
                return item ? (Math.round((item.count * 100) / total)) : 0;
            case 'value':
                return item ? item.count : 0;
            default:
                return 0;
        }
    }

    useEffect(() => {
        getStatistics({ range_type: chartSelectedRange, year: chartSelectedYear });
        getRecipientsStatistics();
    }, [language]);


    return (
        <>
            <div className="flex flex-wrap 3xl:w-4/5 mx-auto px-8 2xl:pt-10 mt-10 2xl:pb-10 max-w-screen-3xl">
                {/* Left Col */}
                <div className="2xl:w-1/2 2xl:pr-3 mb-10 w-full">
                    <div className="bg-skin-bgBoxCol shadow-md rounded-lg">
                        <div className="grid grid-cols-[250px_1fr] h-[200px]">
                            <div className="border-r text-center font-light py-4" >
                                <div className="text-xl text-skin-headerCard"><Trans i18nKey={'sendingActivity'} /></div>
                                <div className="text-base text-skin-default space-y-3">
                                    <p className="text-base"><Trans i18nKey={'totalByPartition'} /></p>
                                    <p className="text-skin-headerCard text-xl"><Trans i18nKey={'total'} /></p>
                                    <p className="text-skin-headerCard text-xl text-iconSent">
                                        <CountUp end={getStats?.count || 0} duration={1} separator={','} />
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col w-full px-8 space-y-5 py-6">
                                <ProgressBar color={'bg-purple-500'} title={"sms"} value={getDataByChannel('SMS', 'value')} percentage={getDataByChannel('SMS', 'percentage')} />
                                <ProgressBar color={'bg-blue-500'} title={"email"} value={getDataByChannel('MAIL', 'value')} percentage={getDataByChannel('MAIL', 'percentage')} />
                                <ProgressBar color={'bg-orange-500'} title={"mobile"} value={getDataByChannel('MOBILEPUSH', 'value')} percentage={getDataByChannel('MOBILEPUSH', 'percentage')} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right col */}
                <div className="2xl:w-1/2 mb-10 2xl:pl-3 w-full space-y-8">
                    <div className="bg-skin-bgBoxCol shadow-md rounded-lg">
                        <div className="grid grid-cols-[250px_1fr] h-[200px]">
                            <div className="border-r text-center font-light py-4" >
                                <div className="text-xl text-skin-headerCard"><Trans i18nKey={'subscriptions'} /></div>
                                <div className="text-base text-skin-default space-y-3">
                                    <p className="text-base"><Trans i18nKey={'totalByPartition'} /></p>
                                    <p className="text-skin-headerCard text-xl"><Trans i18nKey={'total'} /></p>
                                    <p className="text-skin-headerCard text-xl text-iconSent">
                                        <CountUp end={getRecipients?.count || 0} duration={1} separator={','} />
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-row w-full px-8 items-center space-x-12 justify-center py-5">
                                <Tooltip type="Sms" color={getStylesCircle.SMS.stroke} message={`${getDataByChannel('SMS', 'value', true).toLocaleString()}`} >
                                    <div style={{ maxWidth: 150, maxHeight: 150, minHeight: 100, minWidth: 100 }}>
                                        <CircularProgressbarWithChildren styles={{ path: { stroke: getStylesCircle.SMS.stroke, transition: 'stroke-dashoffset 1.2s ease 0s' }, text: { fill: '#5e6f7e' } }} value={getDataByChannel('SMS', 'percentage', true)}>
                                            {getStylesCircle.SMS.icon}
                                            <div className="text-base text-center">
                                                <CountUp end={getDataByChannel('SMS', 'percentage', true)} duration={1} separator={','} />%
                                                <br />
                                                <span className="text-skin-default">Sms</span>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Tooltip>
                                <Tooltip type="Email" color={getStylesCircle.MAIL.stroke} message={`${getDataByChannel('MAIL', 'value', true).toLocaleString()}`} >
                                    <div style={{ maxWidth: 150, maxHeight: 150, minHeight: 100, minWidth: 100 }}>
                                        <CircularProgressbarWithChildren styles={{ path: { stroke: getStylesCircle.MAIL.stroke, transition: 'stroke-dashoffset 1.2s ease 0s' }, text: { fill: '#5e6f7e' } }} value={getDataByChannel('MAIL', 'percentage', true)}>
                                            {getStylesCircle.MAIL.icon}
                                            <div className="text-base text-center">
                                                <CountUp end={getDataByChannel('MAIL', 'percentage', true)} duration={1} separator={','} />%
                                                <br />
                                                <span className="text-skin-default">Email</span>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Tooltip>
                                <Tooltip type="Mobile" color={getStylesCircle.MOBILEPUSH.stroke} message={`${getDataByChannel('MOBILEPUSH', 'value', true).toLocaleString()}`} >
                                    <div style={{ maxWidth: 150, maxHeight: 150, minHeight: 100, minWidth: 100 }}>
                                        <CircularProgressbarWithChildren styles={{ path: { stroke: getStylesCircle.MOBILEPUSH.stroke, transition: 'stroke-dashoffset 1.2s ease 0s' }, text: { fill: '#5e6f7e' } }} value={getDataByChannel('MOBILEPUSH', 'percentage', true)}>
                                            {getStylesCircle.MOBILEPUSH.icon}
                                            <div className="text-base text-center">
                                                <CountUp end={getDataByChannel('MOBILEPUSH', 'percentage', true)} duration={1} separator={','} />%
                                                <br />
                                                <span className="text-skin-default">Mobile</span>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap 3xl:w-4/5 mx-auto px-8 mb-10 2xl:pb-10 max-w-screen-3xl">
                <div className="bg-skin-bgBoxCol shadow-md rounded-lg w-full">
                    <div className="flex flex-row">
                        <div className="border-r text-center font-light py-4" style={{width: 250}}>
                            <div className="text-xl text-skin-headerCard"><Trans i18nKey={'filters'} /></div>
                            <form onSubmit={handleSubmit(SubmitForm)} className="w-full mx-auto space-y-10 relative">
                                <div className="bg-skin-bgBoxCol">
                                    <div className="text-base">
                                        <div className="px-10">
                                            <InputsFields arrFields={FormChartMessagesYear(language)} form={form} method={handleSubmit(SubmitForm)} />
                                            <InputsFields arrFields={FormChartMessagesRange(language)} form={form} method={handleSubmit(SubmitForm)} />
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="px-8 space-y-5 pt-4 pb-6" style={{width: `calc(100% - 250px`}}>
                            <StackAreaChart grapichInfo={getChartInfo} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}