import { debounce } from "debounce";
import { ReactComponent as FilterSvg } from "../../assets/mainIcons/filter.svg"
import { SearchIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeClass } from "../../store/feautures/ThemeSlice";

export default function DebounceSearchInput({ input, form, method }: any) {

    const { register } = form;
    const { theme } = useSelector(ThemeClass);
    const {t} = useTranslation();

    return (
        <div className={`flex justify-between items-center space-x-10 px-8 ${input?.size_input}`} key={input.name}>

            <div className="flex flex-col items-center justify-center space-y-1 fill-iconColor text-skin-default">
                <FilterSvg className="w-5 h-5" />
                <p className="text-sm capitalize"><Trans i18nKey={'filters'} /></p>
            </div>

            <div className="relative fill-iconColor text-skin-default">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <span className="p-1 focus:outline-none focus:shadow-outline">
                        <SearchIcon className="w-6 h-6" />
                    </span>
                </span>
                <input
                    {...register(input.name, { ...input.validators })}
                    className={`placeholder:text-md bg-skin-bgInputSearch w-96 h-10 pl-12 rounded text-md border ${theme === 'dark' ? 'border-skin-input' : 'border-transparent	'} focus-within:border-skin-inputFocus focus:ring-1 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
                    autoComplete="off"
                    placeholder={t(input.placeholder)}
                    type={input.type}
                    aria-describedby={input.placeholder ? input.placeholder : input.label}
                    onChange={debounce((e: any) => { method(e.target.value) }, 500)}
                />
            </div>

        </div>
    )
}
