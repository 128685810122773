import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { CurrentInfoUser } from "../../store/feautures/SetAuthenticatedUserSlice";

type RoleGuardType = {
    children: ReactNode;
    visibilityFor: string[]
};

export function RoleTypeGuard({ children, visibilityFor}: RoleGuardType) {
    const userRole: any = useSelector(CurrentInfoUser);
    const isAuthorizedRole = visibilityFor.includes(userRole?.role);
    return  isAuthorizedRole  ? <>{children}</> : <Navigate to={'/home'} />
}