import { Trans } from "react-i18next";

export const FormChartMessagesYear: any = (language: string) => [
    {
        name: 'year',
        placeholder: '',
        type: 'text',
        validators: { required: 'fieldRequired' },
        type_input: 'react-select',
        size_input: 'w-full my-4',
        options: [
            { value: new Date().getFullYear(), label: new Date().getFullYear() },
            { value: new Date().getFullYear() - 1, label: new Date().getFullYear() - 1 },
            { value: new Date().getFullYear() - 2, label: new Date().getFullYear() - 2 }
        ],
        disable_search: true,
        disable_clear: true
    }
]

export const FormChartMessagesRange: any = (language: string) => [
    {
        name: 'range_type',
        placeholder: '',
        type: 'text',
        validators: { required: 'fieldRequired' },
        type_input: 'react-select',
        size_input: 'w-full my-4',
        options: [
            { value: 'ANNUAL', label: <Trans i18nKey={'annual'} /> },
            { value: 'MONTHLY', label: <Trans i18nKey={'monthly'} /> },
            { value: 'WEEKLY', label: <Trans i18nKey={'weekly'} /> }
        ],
        disable_search: true,
        disable_clear: true
    }
]
