import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import file json with translations
import Translations_it from './locale/translation-it.json';
import Translations_en from './locale/translation-en.json';
import Translations_es from './locale/translation-es.json';

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //Import file translations
    resources: {
      it: {
        translations: Translations_it
      },
      en: {
        translations: Translations_en
      },
      es: {
        translations: Translations_es
      },
    },
    
    fallbackLng: "it",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });