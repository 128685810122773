import { useState } from "react";
import { ReactComponent as EyeIcon } from "../../assets/mainIcons/visualizza_si.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/mainIcons/visualizza_no.svg";
import ErrorMessageInput from "./ErrorMessageInput";
import { useTranslation } from "react-i18next";

export default function InputPasswordMatch({input, form, type}: any) {

    const [show, setShow] = useState(false);
    const { register, formState: { errors }, watch} = form;
    const { t } = useTranslation();

    return(
        <div className={`relative h-16 ${input.size_input} ${errors[input.name] ? 'mb-10' : ''}`}>
            <input
                {...register(input.name, { ...input.validators, validate: (value: any) => value === watch(input.matchedInput) || "La password non corrisponde" })}
                className={`${type === 'login' ? 'pl-5' : ''} bg-transparent peer h-10 mt-6 w-full border-b-2 border-gray-300 text-skin-textInput placeholder-transparent focus:outline-none focus:border-skin-inputFocus text-md`}
                autoComplete={'off'}
                id={input.name}
                type={show ? 'text' : 'password'}
                placeholder={t(input.placeholder)}
            />
            <label htmlFor={input?.name} className={`${type === 'login' ? 'pl-5' : ''} pointer-events-none select-none absolute text-sm left-0 top-2 text-skin-textInput transition-all peer-placeholder-shown:text-skin-textInput peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-8 peer-focus:top-2 peer-focus:text-skin-focus peer-focus:text-sm`}>
                {t(input.label)}
            </label>

            <span className="absolute top-7 right-5 pr-3 flex items-center text-sm leading-5">
                <div className="cursor-pointer p-1 focus:outline-none focus:shadow-outline" onClick={() => setShow(!show)}>
                    {show && <EyeIcon className='w-5 h-5 fill-iconColor' />}
                    {!show && <EyeOffIcon className='w-5 h-5 fill-iconColor' />}
                </div>
            </span>
            <ErrorMessageInput errors={errors} name={input.name} />
        </div>
    )
}