import { Controller } from 'react-hook-form';
import ErrorMessageInput from './ErrorMessageInput';
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import { Trans } from 'react-i18next';

export default function CodeMirrorCode({ form, input, type }: any) {

    const { control, setValue, formState: { errors } } = form;

    return (
        <Controller
            control={control}
            name={input.name}
            rules={input.validators}
            render={({ field }: any) => (
                <div className='my-5 space-y-2'>
                    <label className='text-skin-default text-sm' htmlFor={input.name}>{<Trans i18nKey={input?.label} />}</label>
                    <AceEditor
                        height={input.height}
                        fontSize={14}
                        mode={input?.mode ? input?.mode : 'json'}
                        theme="twilight"
                        placeholder={input.placeholder}
                        value={field.value}
                        width="100%"
                        readOnly={type === 'view' ? true : false}
                        setOptions={{hScrollBarAlwaysVisible: false, vScrollBarAlwaysVisible: false}}
                        onChange={(value) => {
                            setValue(input.name, value, { shouldDirty: value.length >= 5 ? true : false })
                        }}
                    />
                    <ErrorMessageInput errors={errors} name={input.name} />
                </div>
            )}
        />
    )
}