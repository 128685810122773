import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./Auth";

type PrivateGuardProps = {
    children: ReactNode;
};

export default function PrivateRouterGuard({children}: PrivateGuardProps)  {
    const isAutenticate = useAuth();
    return isAutenticate ? <>{children}</> : <Navigate to={'/'} replace />
}