import { useSelector } from "react-redux"
import { ThemeClass } from "../../store/feautures/ThemeSlice"

export default function Loader() {

    const { theme } = useSelector(ThemeClass);

    return (
        <div className={`${theme} fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center flex-col bg-skin-bgBoxCol opacity-90 z-50`}>
            <div className=" flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-skin-loader"></div>
            </div>
        </div>
    )
}