import CountUp from 'react-countup';

export default function ProgressBar({ color, title, value, percentage }: any) {
    return (
        <>
            <div className="flex flex-col w-full text-skin-default">
                <div className="flex items-center justify-between">
                    <span className="capitalize">{title}</span>
                    <span><CountUp end={value} duration={1} separator={','} /></span>
                </div>
                <div className="basis-1/3">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div className={`${color} h-2.5 rounded-full progressBarAnimation`} style={{ width: `${percentage <= 1 ? 1 : percentage}%` }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}       