import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";

export interface IRememberMe {
    active: boolean,
    email: string | null
}

const setRememberMeSlice = createSlice({
    name: 'SET_REMEMBERME',
    initialState: {
        rememberme: false,
        email: ''
    },

    reducers: {
        setRememberMe: (state, {payload}: PayloadAction<any>) => {
            return {...payload};
        }
    }
    
})

export const { setRememberMe } = setRememberMeSlice.actions;

//EXPORT SELECTRO FOR CHILD
export const RememberMeInfo = (state: RootState) => state.setRememberMeSlice;

export default setRememberMeSlice.reducer