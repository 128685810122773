import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";

export default function SimpleRadioBtn({ input, form }: any) {

    const { control } = form;

    return (
        <Controller
        control={control}
        name={input.name}
        rules={input.validators}
        render={({ field: { onChange, value } }) => (
        <div className="flex items-center mb-4">
            <input
                type="radio"
                value={value}
                className="h-5 w-5 border-skin-inputDefault accent-sky-600 cursor-pointer"
                aria-labelledby={input.label}
                aria-describedby={input.label}
                onChange={() => {onChange(input.value)}}
                checked={(input.value === value) ? true : false}
            />
            <label htmlFor={input.name} className="text-skin-default text-base ml-2 block">
                {<Trans i18nKey={input.label} />}
            </label>
        </div>
        )}
      />
    )
}