import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AesCrypto } from "../../helpers/AesCrypto";
import { Login, Logout } from "../../services/AppServices";
import { RootState } from "../Store";

export interface IInitialStateSliceUSer {
  userInfo: null | any,
  status: string,
  isAuthenticated: string
}

export const initialStateSliceUSer: IInitialStateSliceUSer = {
  userInfo: null,
  status: '',
  isAuthenticated: AesCrypto('false')
}

export const setCurrentUserInfo: any = createAsyncThunk(
  "login/setCurrentInfoUser",
  async (dataForm, thunkAPI: any) => {
    try {
      return await Login(dataForm)
    }
    catch (err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const setLogout: any = createAsyncThunk(
  "logout/setLogout",
  async (thunkAPI: any) => {
    try {
      return await Logout();
    }
    catch (err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

const setInfoCurrentUser = createSlice({
  name: 'SET_CURRENT_USER',
  initialState: initialStateSliceUSer,

  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<IInitialStateSliceUSer>) => {
      return { ...payload };
    }
  },

  extraReducers: {
    [setCurrentUserInfo.pending]: (state: any) => {
      state.status = 'loading';
      state.isAuthenticated = AesCrypto('false');
    },
    [setCurrentUserInfo.fulfilled]: (state: any, { payload }: { payload: any }) => {
      state.isAuthenticated = AesCrypto('true');
      state.status = 'success';
      state.userInfo = payload;
    },
    [setCurrentUserInfo.rejected]: (state: any, { payload }) => {
      state.status = 'failed';
      state.isAuthenticated = AesCrypto('false');
      state.userInfo = null;
    },

    [setLogout.pending]: (state: any) => {
      state.status = 'loading';
    },
    [setLogout.fulfilled]: (state: any) => {
      state.status = 'success';
      state.isAuthenticated = AesCrypto('false');
      state.userInfo = null;
    },
    [setLogout.rejected]: (state: any) => {
      state.status = 'failed';
    },
  },
})

export const { setCurrentUser } = setInfoCurrentUser.actions;

//EXPORT SELECTRO FOR CHILD
export const CurrentInfoUser = (state: RootState) => state.setInfoCurrentUser.userInfo;
export const IsAutenticate = (state: RootState) => state.setInfoCurrentUser.isAuthenticated;
export const CurrentInfoUserStateCall = (state: RootState) => state.setInfoCurrentUser.status;

export default setInfoCurrentUser.reducer