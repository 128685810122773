import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ErrorMessageInput from "./ErrorMessageInput";

export default function InputPhoneNum({ input, form, type }: any) {

    const { control, formState: { errors }, setValue, watch } = form;
    const [isLabeling, setLabeling] = useState(false);
    const [isLabelingNoFocus, setLabelingNoFocus] = useState(false);
    let value = watch(input.name);

    useEffect(() => {
        value ? setLabelingNoFocus(true) : setLabelingNoFocus(false)
    }, [watch, input, value])

    return (
        <div className={`${input.size_input} h-16 relative`} >
            <Controller
                control={control}
                name={input.name}
                rules={input.validators}
                render={({ field }: any) => (
                    <PhoneInput
                        disabled={type === 'view' ? true : false}
                        onFocus={() => { setLabeling(true); setLabelingNoFocus(false) }}
                        onBlur={() => { setLabeling(false); setLabelingNoFocus(true) }}
                        containerClass={`${isLabeling ? 'border-skin-inputFocus placeholder-opacity-0' : 'border-gray-300'} bg-transparent peer h-10 mt-6 w-full ${type === 'view' ? 'border-dotted' : 'border-solid'} border-b-2 placeholder-transparent focus:outline-none text-md`}
                        inputStyle={{ color: "var(--color-text-input)", background: "transparent", border: 'none', fontSize: 17, }}
                        buttonStyle={{ background: "transparent", border: 'none' }}
                        placeholder={(isLabeling || isLabelingNoFocus) ? '' : input.placeholder}
                        country={'it'}
                        regions={'europe'}
                        value={field.value}
                        autoFormat={true}
                        onChange={(value, data, event, formattedValue) => {setValue(input.name, formattedValue, { shouldValidate: true }) }}
                    />
                )}
            />

            <label className={`transition-all text-sm text-skin-textInput ${(isLabeling || isLabelingNoFocus) ? isLabelingNoFocus ? 'absolute top-0 left-0 text-skin-textInput' : 'text-skin-focus absolute top-0 left-0' : 'absolute top-8 left-12 pointer-events-none select-none  hidden'} `}>
                {<Trans i18nKey={input.label} />}
            </label>
            <ErrorMessageInput errors={errors} name={input.name} />
        </div>
    );
}