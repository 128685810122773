import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateUser, DeleteUser, GetUserById, GetUsers, ImpersonateUser, UpdateUser } from "../../services/UsersServices";
import { IUser } from "../../utils/models/UsersModels";
import { RootState } from "../Store";

export const getListUsers: any = createAsyncThunk(
  "users/getListUsers",
  async (data: any,thunkAPI: any) => {
    try 
    {
      return await GetUsers(data?.params, data?.paramsHeader)
    }
    catch(err: any) {
        console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getUserById: any = createAsyncThunk(
    "users/getUserById",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await GetUserById(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const updateUser: any = createAsyncThunk(
    "users/updateUser",
    async (dataForm: {id: string, data: IUser}, thunkAPI: any) => {
      try 
      {
        return await UpdateUser(dataForm.id, dataForm.data)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const createUser: any = createAsyncThunk(
    "users/createUser",
    async (dataForm: IUser, thunkAPI: any) => {
      try 
      {
        return await CreateUser(dataForm)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const deleteUser: any = createAsyncThunk(
    "users/deleteUser",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await DeleteUser(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const impersonateUser: any = createAsyncThunk(
    "users/impersonateUser",
    async (data: {email?: string, consumer?: string, token?: string}, thunkAPI: any) => {
      try 
      {
        let {token, ...body}: any = data;
        return await ImpersonateUser(body, data?.token)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );


const setCrudUsers = createSlice({
    name: 'SET_CRUD_USERS',
    initialState: {
        statusGet: 'idl',
        statusGetById: 'idl',
        statusCreate: 'idl',
        statusUpdate: 'idl',
        statusDelete: 'idl',
        statusImpersonate: 'idl'
    },
  
    reducers: {},
  
    extraReducers: {
      [getListUsers.pending]: (state: any) => {
        state.statusGet = 'loading';
      },
      [getListUsers.fulfilled]: (state: any) => {
        state.statusGet = 'success';
      },
      [getListUsers.rejected]: (state: any) => {
        state.statusGet = 'failed';
      },

      [getUserById.pending]: (state: any) => {
        state.statusGetById = 'loading';
      },
      [getUserById.fulfilled]: (state: any) => {
        state.statusGetById = 'success';
      },
      [getUserById.rejected]: (state: any) => {
        state.statusGetById = 'failed';
      },

      [updateUser.pending]: (state: any) => {
        state.statusUpdate = 'loading';
      },
      [updateUser.fulfilled]: (state: any) => {
        state.statusUpdate = 'success';
      },
      [updateUser.rejected]: (state: any) => {
        state.statusUpdate = 'failed';
      },

      [createUser.pending]: (state: any) => {
        state.statusCreate = 'loading';
      },
      [createUser.fulfilled]: (state: any) => {
        state.statusCreate = 'success';
      },
      [createUser.rejected]: (state: any) => {
        state.statusCreate = 'failed';
      },

      [deleteUser.pending]: (state: any) => {
        state.statusDelete = 'loading';
      },
      [deleteUser.fulfilled]: (state: any) => {
        state.statusDelete = 'success';
      },
      [deleteUser.rejected]: (state: any) => {
        state.statusDelete = 'failed';
      },

      [impersonateUser.pending]: (state: any) => {
        state.statusImpersonate = 'loading';
      },
      [impersonateUser.fulfilled]: (state: any) => {
        state.statusImpersonate = 'success';
      },
      [impersonateUser.rejected]: (state: any) => {
        state.statusImpersonate = 'failed';
      },

    },
  })

//EXPORT SELECTRO FOR CHILD
export const StateCrudUsers = (state: RootState) => state.setCrudUsers;

export default setCrudUsers.reducer