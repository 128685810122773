import CodeMirrorCode from "../components/inputs/CodeMirrorInput";
import DebounceSearchInput from "../components/inputs/DebounceSearchInput";
import DefaultInput from "../components/inputs/DefaultInput";
import InputFile from "../components/inputs/InputFile";
import InputPasswordMatch from "../components/inputs/InputPasswordMatch";
import InputPassword from "../components/inputs/PasswordInput";
import InputPhoneNum from "../components/inputs/PhoneNumberInput";
import ReactSelectInput from "../components/inputs/SelectReact";
import SimpleRadioBtn from "../components/inputs/SimpleRadioBtnInput";

export default function InputsFields(props: any) {

    const CreateInput = (input: any) => {
        switch (input.type_input) {
            case 'input': return <DefaultInput input={input} form={props.form} key={input.name} type={props.typeView} />
            case 'password': return <InputPassword input={input} form={props.form} key={input.name} type={props.typeView} />
            case 'match-password': return <InputPasswordMatch input={input} form={props.form} key={input.name} type={props.typeView} />
            case 'react-select': return <ReactSelectInput input={input} form={props.form} options={props.options} key={input.name} type={props.typeView} method={props.method} />
            case 'phone': return <InputPhoneNum input={input} form={props.form} key={input.name} type={props.typeView} />
            case 'debounce-input': return <DebounceSearchInput input={input} form={props.form} key={input.name} method={props.method} />
            case 'uploadFile': return <InputFile input={input} form={props.form} key={input.name} logoUrl={props.logoUrl} type={props.typeView} />
            case 'code': return <CodeMirrorCode input={input} form={props.form} key={input.name} type={props.typeView} />
            case 'radio': return <SimpleRadioBtn input={input} form={props.form} key={input.value} type={props.typeView} />
        }
    }

    return (
        <>
            {props.arrFields.map((input: any) => {
                const Input = CreateInput(input);
                return props.class ? <div className={props.class} key={input.name}>{Input}</div> : Input;
            })
            }
        </>
    )
}