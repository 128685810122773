import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";

export default function ErrorMessageInput({errors, name}: any) {

  const {t} = useTranslation();

    return(
        <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="text-sm text-red-600 py-2 text-left">{t(message) ? t(message) : message}</p>}
      />
    )
}