import { ReactNode, useRef } from "react";
import useMouse from '@react-hook/mouse-position';

export const Tooltip = ({
  message, 
  type,
  color,
  children
}: {
  message: any , type?: string, color?: string, children?: ReactNode
}) => {

  const ref = useRef(null)
  const mouse = useMouse(ref, {
    enterDelay: 10,
    leaveDelay: 10
  })

  const checkMouse = (): any => {
    let valueX: any = mouse.x;
    let valueY: any = mouse.y;
    return valueX && valueX > 0 && valueX < 135 && valueY && valueY > 0 && valueY < 135 ? 'visible' : 'hidden';
  }
  
  return (
    <div className="relative items-center group cursor-pointer" ref={ref}>
      {children}
      <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex whitespace-pre" style={{visibility: checkMouse(), left: mouse.x || 'auto', top: mouse.y || 'auto'}}>
        <span className="relative z-10 leading-none text-slate-600 whitespace-no-wrap bg-white tootipBox rounded-md w-full">
          <span className="dotChannel" style={{backgroundColor: color}}></span> <span className="text-sm">{type}</span> <span className="text-sm ml-1 font-medium">{message}</span>
        </span>
      </div>
    </div>
  );
};