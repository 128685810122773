import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateRecipient, DeleteRecipient, GetRecipientById, GetRecipient, UpdateRecipient, GetStatsRecipients } from "../../services/RecipientsServices";
import { IRecipients } from "../../utils/models/RecipientsModels";
import { RootState } from "../Store";

export const getListRecipients: any = createAsyncThunk(
  "recipients/getListRecipients",
  async (data: any, thunkAPI: any) => {
    try {
      return await GetRecipient(data?.params, data?.paramsHeader)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getRecipientById: any = createAsyncThunk(
  "recipient/getRecipientById",
  async (id: string, thunkAPI: any) => {
    try {
      return await GetRecipientById(id)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateRecipient: any = createAsyncThunk(
  "recipient/updateRecipient",
  async (dataForm: { id: string, data: IRecipients }, thunkAPI: any) => {
    try {
      return await UpdateRecipient(dataForm.id, dataForm.data)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const createRecipient: any = createAsyncThunk(
  "recipient/createRecipient",
  async (dataForm: IRecipients, thunkAPI: any) => {
    try {
      return await CreateRecipient(dataForm)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const deleteRecipient: any = createAsyncThunk(
  "recipient/deleteRecipient",
  async (id: string, thunkAPI: any) => {
    try {
      return await DeleteRecipient(id)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getStatsRecipients: any = createAsyncThunk(
  "recipient/getStatsRecipients",
  async (data: any, thunkAPI: any) => {
    try {
      return await GetStatsRecipients(data?.params, data?.paramsHeader)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET STATS', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);


const setCrudRecipients = createSlice({
  name: 'SET_CRUD_RECIPIENTS',
  initialState: {
    statusGet: 'idl',
    statusGetById: 'idl',
    statusCreate: 'idl',
    statusUpdate: 'idl',
    statusDelete: 'idl',
  },

  reducers: {},

  extraReducers: {
    [getListRecipients.pending]: (state: any) => {
      state.statusGet = 'loading';
    },
    [getListRecipients.fulfilled]: (state: any) => {
      state.statusGet = 'success';
    },
    [getListRecipients.rejected]: (state: any) => {
      state.statusGet = 'failed';
    },

    [getRecipientById.pending]: (state: any) => {
      state.statusGetById = 'loading';
    },
    [getRecipientById.fulfilled]: (state: any) => {
      state.statusGetById = 'success';
    },
    [getRecipientById.rejected]: (state: any) => {
      state.statusGetById = 'failed';
    },

    [updateRecipient.pending]: (state: any) => {
      state.statusUpdate = 'loading';
    },
    [updateRecipient.fulfilled]: (state: any) => {
      state.statusUpdate = 'success';
    },
    [updateRecipient.rejected]: (state: any) => {
      state.statusUpdate = 'failed';
    },

    [createRecipient.pending]: (state: any) => {
      state.statusCreate = 'loading';
    },
    [createRecipient.fulfilled]: (state: any) => {
      state.statusCreate = 'success';
    },
    [createRecipient.rejected]: (state: any) => {
      state.statusCreate = 'failed';
    },

    [deleteRecipient.pending]: (state: any) => {
      state.statusDelete = 'loading';
    },
    [deleteRecipient.fulfilled]: (state: any) => {
      state.statusDelete = 'success';
    },
    [deleteRecipient.rejected]: (state: any) => {
      state.statusDelete = 'failed';
    },

    [getStatsRecipients.pending]: (state: any) => {
      state.statusGetStatsMessages = 'loading';
    },
    [getStatsRecipients.fulfilled]: (state: any) => {
      state.statusGetStatsMessages = 'success';
    },
    [getStatsRecipients.rejected]: (state: any) => {
      state.statusGetStatsMessages = 'failed';
    }

  },
})

//EXPORT SELECTRO FOR CHILD
export const StateCrudRecipients = (state: RootState) => state.setCrudRecipients;

export default setCrudRecipients.reducer