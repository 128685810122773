import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./Auth";

type PublicGuardProps = {
    children: ReactNode;
};

export default function PublicRouterGuard({children}: PublicGuardProps)  {
    const isAutenticate = useAuth();
    return isAutenticate ? <Navigate to={'/home'} replace /> : <>{children}</> 
}