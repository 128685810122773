import { queryStringHelper } from "../helpers/queryString";
import { axiosInterceptor } from "../interceptors/AppInterceptor";
import { IRecipients } from "../utils/models/RecipientsModels";

export async function GetRecipient(params: any, paramsHeader: any) {
    const queryString = params ? `?fullText=${params}` : '' 
    return axiosInterceptor.get(`/api/v1/recipients${queryString}`, {headers: {...paramsHeader}})
}

export async function GetRecipientById(id: string) {
    return axiosInterceptor.get(`/api/v1/recipients/${id}`)
}

export async function UpdateRecipient(id: string, dataForm: IRecipients) {
    return axiosInterceptor.patch(`/api/v1/recipients/${id}`, dataForm)
}

export async function CreateRecipient(dataForm: IRecipients) {
    return axiosInterceptor.post(`/api/v1/recipients`, dataForm)
}

export async function DeleteRecipient(id: string) {
    return axiosInterceptor.delete(`/api/v1/recipients/${id}`)
}

export async function GetStatsRecipients(params: any, paramsHeader: any) {
    let queryString: string = queryStringHelper(params);
    return axiosInterceptor.get(`/api/v1/recipients/statistics${queryString}`, { headers: { ...paramsHeader } })
}
