import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateMessage, DeleteMessage, GetMessageById, GetMessages, UpdateMessage, SendMessage, GetStatsMessages } from "../../services/MessagesServices";
import { IMessage } from "../../utils/models/MessagesModels";
import { RootState } from "../Store";

export const getListMessages: any = createAsyncThunk(
  "messages/getListMessages",
  async (data: any, thunkAPI: any) => {
    try {
      return await GetMessages(data?.params, data?.paramsHeader)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getMessageById: any = createAsyncThunk(
  "messages/getMessageById",
  async (id: string, thunkAPI: any) => {
    try {
      return await GetMessageById(id)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateMessage: any = createAsyncThunk(
  "messages/updateMessage",
  async (dataForm: { id: string, data: IMessage }, thunkAPI: any) => {
    try {
      return await UpdateMessage(dataForm.id, dataForm.data)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const createMessage: any = createAsyncThunk(
  "messages/createMessage",
  async (dataForm: IMessage, thunkAPI: any) => {
    try {
      return await CreateMessage(dataForm)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const deleteMessage: any = createAsyncThunk(
  "messages/deleteMessage",
  async (id: string, thunkAPI: any) => {
    try {
      return await DeleteMessage(id)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const sendMessage: any = createAsyncThunk(
  "messages/sendMessage",
  async (dataForm: { channel: string, data: IMessage }, thunkAPI: any) => {
    try {
      return await SendMessage(dataForm.channel, dataForm.data)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET BY ID', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getStatsMessages: any = createAsyncThunk(
  "messages/getStatsMessages",
  async (data: any, thunkAPI: any) => {
    try {
      return await GetStatsMessages(data?.params, data?.paramsHeader)
    }
    catch (err: any) {
      console.log('ERROR SLICE GET STATS', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);


const setCrudMessages = createSlice({
  name: 'SET_CRUD_MESSAGES',
  initialState: {
    statusGet: 'idl',
    statusGetById: 'idl',
    statusCreate: 'idl',
    statusUpdate: 'idl',
    statusDelete: 'idl',
    statusSend: 'idl',
    statusGetStatsMessages: 'idl'
  },

  reducers: {},

  extraReducers: {
    [getListMessages.pending]: (state: any) => {
      state.statusGet = 'loading';
    },
    [getListMessages.fulfilled]: (state: any) => {
      state.statusGet = 'success';
    },
    [getListMessages.rejected]: (state: any) => {
      state.statusGet = 'failed';
    },

    [getMessageById.pending]: (state: any) => {
      state.statusGetById = 'loading';
    },
    [getMessageById.fulfilled]: (state: any) => {
      state.statusGetById = 'success';
    },
    [getMessageById.rejected]: (state: any) => {
      state.statusGetById = 'failed';
    },

    [updateMessage.pending]: (state: any) => {
      state.statusUpdate = 'loading';
    },
    [updateMessage.fulfilled]: (state: any) => {
      state.statusUpdate = 'success';
    },
    [updateMessage.rejected]: (state: any) => {
      state.statusUpdate = 'failed';
    },

    [createMessage.pending]: (state: any) => {
      state.statusCreate = 'loading';
    },
    [createMessage.fulfilled]: (state: any) => {
      state.statusCreate = 'success';
    },
    [createMessage.rejected]: (state: any) => {
      state.statusCreate = 'failed';
    },

    [deleteMessage.pending]: (state: any) => {
      state.statusDelete = 'loading';
    },
    [deleteMessage.fulfilled]: (state: any) => {
      state.statusDelete = 'success';
    },
    [deleteMessage.rejected]: (state: any) => {
      state.statusDelete = 'failed';
    },

    [sendMessage.pending]: (state: any) => {
      state.statusSend = 'loading';
    },
    [sendMessage.fulfilled]: (state: any) => {
      state.statusSend = 'success';
    },
    [sendMessage.rejected]: (state: any) => {
      state.statusSend = 'failed';
    },

    [getStatsMessages.pending]: (state: any) => {
      state.statusGetStatsMessages = 'loading';
    },
    [getStatsMessages.fulfilled]: (state: any) => {
      state.statusGetStatsMessages = 'success';
    },
    [getStatsMessages.rejected]: (state: any) => {
      state.statusGetStatsMessages = 'failed';
    }
  },
})

//EXPORT SELECTRO FOR CHILD
export const StateCrudMessages = (state: RootState) => state.setCrudMessages;

export default setCrudMessages.reducer