import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk'
import SetOpenBoxUserSlice from "./feautures/SetOpenBoxUserSlice";
import setInfoCurrentUser from "./feautures/SetAuthenticatedUserSlice";
import setCrudUsers from "./feautures/CrudUserSlice";
import setCrudConsumers from "./feautures/CrudConsumersSlice";
import setThemeClass from "./feautures/ThemeSlice";
import setRememberMeSlice from "./feautures/RememberMeSlice";
import setCrudRecipients from "./feautures/CrudRecipientSlice";
import setPasswordAction from "./feautures/PasswordSlice";
import setCrudFilters from "./feautures/CrudFiltersSlice";
import setCrudSettings from "./feautures/CrudSettingsSlice";
import setCrudMessages from "./feautures/CrudMessageSlice";
import setCrudTemplates from "./feautures/CrudTemplatesSlice";
import setEmailOwner from "./feautures/ImpersonateSlice";
import setFileConnector from "./feautures/UploadSdkSlice";

export const rootReducer = combineReducers({
  setOpenBoxUserViewSlice: SetOpenBoxUserSlice,
  setInfoCurrentUser: setInfoCurrentUser,
  setThemeClass: setThemeClass,
  setRememberMeSlice: setRememberMeSlice,
  // USERS
  setCrudUsers: setCrudUsers,
  // CONSUMERS
  setCrudConsumers: setCrudConsumers,
  // PASSWORD
  setPasswordAction: setPasswordAction,
  // RECIPIENTS
  setCrudRecipients: setCrudRecipients,
  // FILTERS
  setCrudFilters: setCrudFilters,
  //SETTINGS
  setCrudSettings: setCrudSettings,
  setFileConnector: setFileConnector,
  //MESSAGES
  setCrudMessages: setCrudMessages,
  //TEMPLATES
  setCrudTemplates: setCrudTemplates,
  //IMPERSONATE
  setEmailOwner: setEmailOwner
});
  
  export type RootState = ReturnType<typeof rootReducer>

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['setInfoCurrentUser', 'setThemeClass', 'setRememberMeSlice', 'setEmailOwner'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  });