import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChangePassword, ResetPassword, SentEmailPassword } from "../../services/AppServices";
import { RootState } from "../Store";

export const changePassword: any = createAsyncThunk(
    "password/changePassword",
    async (dataForm: any, thunkAPI: any) => {
        try {
            return await ChangePassword(dataForm)
        }
        catch (err: any) {
            console.log('ERROR SLICE GET BY ID', err)
            return thunkAPI.rejectWithValue({ error: err.message });
        }
    }
);

export const sentEmailPassword: any = createAsyncThunk(
    "password/sentEmailPassword",
    async (dataForm: any, thunkAPI: any) => {
        try {
            return await SentEmailPassword(dataForm)
        }
        catch (err: any) {
            console.log('ERROR SLICE GET BY ID', err)
            return thunkAPI.rejectWithValue({ error: err.message });
        }
    }
);

export const resetPassword: any = createAsyncThunk(
    "password/resetPassword",
    async (dataForm: any, thunkAPI: any) => {
        try {
            return await ResetPassword(dataForm?.token, dataForm?.data)
        }
        catch (err: any) {
            console.log('ERROR SLICE GET BY ID', err)
            return thunkAPI.rejectWithValue({ error: err.message });
        }
    }
);

const setPasswordAction = createSlice({
    name: 'SET_PASSWORD_ACTION',
    initialState: {
        statusPasswordChange: 'idl',
        statusPasswordReset: 'idl',
        statusPasswordSent: 'idl',
    },

    reducers: {},

    extraReducers: {
        [changePassword.pending]: (state: any) => {
            state.statusPasswordChange = 'loading';
        },
        [changePassword.fulfilled]: (state: any) => {
            state.statusPasswordChange = 'success';
        },
        [changePassword.rejected]: (state: any) => {
            state.statusPasswordChange = 'failed';
        },

        [sentEmailPassword.pending]: (state: any) => {
            state.statusPasswordSent = 'loading';
        },
        [sentEmailPassword.fulfilled]: (state: any) => {
            state.statusPasswordSent = 'success';
        },
        [sentEmailPassword.rejected]: (state: any) => {
            state.statusPasswordSent = 'failed';
        },

        [resetPassword.pending]: (state: any) => {
            state.statusPasswordReset = 'loading';
        },
        [resetPassword.fulfilled]: (state: any) => {
            state.statusPasswordReset = 'success';
        },
        [resetPassword.rejected]: (state: any) => {
            state.statusPasswordReset = 'failed';
        },

    },
})

//EXPORT SELECTRO FOR CHILD
export const StateActionPass = (state: RootState) => state.setPasswordAction;

export default setPasswordAction.reducer