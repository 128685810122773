import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Loader from './components/main/Loader';
import RoutesList from './routing/Routes';
import { ThemeClass } from './store/feautures/ThemeSlice';

function App() {

  const {theme} = useSelector(ThemeClass);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <div className={`${theme}`}>
          <RoutesList />
        </div>
        
      </Suspense>
    </Router>
  );
}

export default withTranslation()(App);
