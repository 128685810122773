
import ReactECharts from 'echarts-for-react';

export default function StackAreaChart({ grapichInfo }: any) {
  const { categories, series } = JSON.parse(grapichInfo);
  const option: any = {
    animationDuration: 1500,
    color: ['rgb(167, 85, 246)', 'rgb(59, 130, 245)', 'rgb(249, 115, 22)'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        mark: { show: true },
        saveAsImage: { show: true, name: 'Report' }
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: categories,
      axisLabel: {
        rotate: 45,
        margin: 15
      },
      splitLine: {
        show: true
      },
    },
    yAxis: {
      type: 'value'
    },
    series: series,
  }

  return (
    <ReactECharts option={option} notMerge={true} style={{ height: 400 }} />
  )
}