
export const queryStringHelper = (params: any) => {
    let queryString: string = '';
    let counter = 0;
    for (let param in params) {
        if (!(/\?/.test(queryString))) {
            queryString += '?'
        }
        if (counter >= 1) queryString += '&'
        queryString += `${param}=${params[param]}`
        counter++;
    }
    return queryString;
}