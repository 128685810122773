import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateFilter, DeleteFilter, GetFilterById, GetFilters, UpdateFilter, ExecuteFilter } from "../../services/FiltersServices";
import { IFilters } from "../../utils/models/FiltersModels";
import { RootState } from "../Store";

export const getListFilters: any = createAsyncThunk(
  "filters/getListFilters",
  async (data: any,thunkAPI: any) => {
    try 
    {
      return await GetFilters(data?.params, data?.paramsHeader)
    }
    catch(err: any) {
        console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getFilterById: any = createAsyncThunk(
    "filters/getFilterById",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await GetFilterById(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const updateFilter: any = createAsyncThunk(
    "filters/updateFilter",
    async (dataForm: {id: string, data: IFilters}, thunkAPI: any) => {
      try 
      {
        return await UpdateFilter(dataForm.id, dataForm.data)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const createFilter: any = createAsyncThunk(
    "filters/createFilter",
    async (dataForm: any, thunkAPI: any) => {
      try 
      {
        return await CreateFilter(dataForm)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const deleteFilter: any = createAsyncThunk(
    "filters/deleteFilter",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await DeleteFilter(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const executeFilter: any = createAsyncThunk(
    "filters/executeFilter",
    async (dataForm: any, thunkAPI: any) => {
      try 
      {
        return await ExecuteFilter(dataForm)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );


const setCrudFilters = createSlice({
    name: 'SET_CRUD_FILTERS',
    initialState: {
        statusGet: 'idl',
        statusGetById: 'idl',
        statusCreate: 'idl',
        statusUpdate: 'idl',
        statusDelete: 'idl',
        statusExecute: 'idl'
    },
  
    reducers: {},
  
    extraReducers: {
      [getListFilters.pending]: (state: any) => {
        state.statusGet = 'loading';
      },
      [getListFilters.fulfilled]: (state: any) => {
        state.statusGet = 'success';
      },
      [getListFilters.rejected]: (state: any) => {
        state.statusGet = 'failed';
      },

      [getFilterById.pending]: (state: any) => {
        state.statusGetById = 'loading';
      },
      [getFilterById.fulfilled]: (state: any) => {
        state.statusGetById = 'success';
      },
      [getFilterById.rejected]: (state: any) => {
        state.statusGetById = 'failed';
      },

      [updateFilter.pending]: (state: any) => {
        state.statusUpdate = 'loading';
      },
      [updateFilter.fulfilled]: (state: any) => {
        state.statusUpdate = 'success';
      },
      [updateFilter.rejected]: (state: any) => {
        state.statusUpdate = 'failed';
      },

      [createFilter.pending]: (state: any) => {
        state.statusCreate = 'loading';
      },
      [createFilter.fulfilled]: (state: any) => {
        state.statusCreate = 'success';
      },
      [createFilter.rejected]: (state: any) => {
        state.statusCreate = 'failed';
      },

      [deleteFilter.pending]: (state: any) => {
        state.statusDelete = 'loading';
      },
      [deleteFilter.fulfilled]: (state: any) => {
        state.statusDelete = 'success';
      },
      [deleteFilter.rejected]: (state: any) => {
        state.statusDelete = 'failed';
      },

      [executeFilter.pending]: (state: any) => {
        state.statusExecute = 'loading';
      },
      [executeFilter.fulfilled]: (state: any) => {
        state.statusExecute = 'success';
      },
      [executeFilter.rejected]: (state: any) => {
        state.statusExecute = 'failed';
      }

    },
  })

//EXPORT SELECTRO FOR CHILD
export const StateCrudFilters = (state: RootState) => state.setCrudFilters;

export default setCrudFilters.reducer