import { Controller } from "react-hook-form";
import Dropzone from "./DropZone";
import ErrorMessageInput from "./ErrorMessageInput";


export default function InputFile({ input, form, logoUrl, type }: any) {
  
  const { control, formState: { errors } } = form

  return (
    <Controller
      name={input.name}
      control={control}
      rules={input.validators}
      render={({ field: { onChange }}: any) => (
        <div className="flex flex-col w-full my-8">
          <Dropzone onChange={(e: any) => onChange(e.target.files[0])} form={form} label={input.label} name={input.name} logoUrl={type !== 'create' ? logoUrl : null} type={type} validator={input?.validatorType}/>
          <ErrorMessageInput errors={errors} name={input.name} />
        </div>
        )}
    />
  );
};