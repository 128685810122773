import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { initialStateSliceUSer, setCurrentUser } from '../store/feautures/SetAuthenticatedUserSlice';
import { store } from '../store/Store';
import { IUser } from '../utils/models/UsersModels';

axios.defaults.baseURL = process.env.REACT_APP_END_POINT ? process.env.REACT_APP_END_POINT : '';
export const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.request.use(async (config) => {
  if (!config?.url?.includes('login')) {
    const userInfo: IUser | null | any = store.getState().setInfoCurrentUser?.userInfo;
    config.headers = { 'Authorization': `Bearer ${userInfo.token}`, ...config.headers }
  }
  return config
}, (error) => {
  return Promise.reject(error);
});

axiosInterceptor.interceptors.response.use((response) => {
  if (response.status === 200) {
    return Promise.resolve(response.data)
  }
}, (error) => {
  if (error.response.status === 401) {
    const navigate = useNavigate()
    store.dispatch(setCurrentUser(initialStateSliceUSer))
    navigate('/')
  }
  return Promise.reject(error?.response?.data);
});