import { axiosInterceptor } from "../interceptors/AppInterceptor";

export async function GetConsumers(params: any, paramsHeader: any) {
    const queryString = params ? `?fullText=${params}` : '' 
    return axiosInterceptor.get(`/api/v1/consumers${queryString}`, {headers: {...paramsHeader}})
}

export async function GetConsumerById(id: string) {
    return axiosInterceptor.get(`/api/v1/consumers/${id}`)
}

export async function UpdateConsumer(id: string, dataForm: any) {
    return axiosInterceptor.patch(`/api/v1/consumers/${id}`, dataForm)
}

export async function CreateConsumer(dataForm: any) {
    return axiosInterceptor.post(`/api/v1/consumers`, dataForm)
}

export async function DeleteConsumer(id: string) {
    return axiosInterceptor.delete(`/api/v1/consumers/${id}`)
}