import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";

export interface ISliceOwnerImpersonate {
    firstname: string | null,
    lastname: string | null,
    email: string | null,
    token: string | null,
    isShowBtn: boolean
}

export const InitialValueImpersonate: ISliceOwnerImpersonate = {
    firstname: null,
    lastname: null,
    email: null,
    token: null,
    isShowBtn: false
}

const setEmailOwner = createSlice({
    name: 'SET_IMPERSONATE_EMAIL',
    initialState: InitialValueImpersonate,

    reducers: {
        setEmailImpersonate: (state, {payload}: PayloadAction<ISliceOwnerImpersonate>) => {
            return {...payload};
        }
    }
    
})

export const { setEmailImpersonate } = setEmailOwner.actions;

//EXPORT SELECTRO FOR CHILD
export const EmailOwnerImpersonate = (state: RootState) => state.setEmailOwner;

export default setEmailOwner.reducer