import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
  CreateEmailConnector, 
  GetConnector, 
  UpdateEmailConnector, 
  CreateIosConnector, 
  UpdateIosConnector, 
  CreateAndroidConnector, 
  UpdateAndroidConnector, 
  CreateSmsConnector,
  UpdateSmsConnector,
  GetActiveConnector,
  CreateHuaweiConnector,
  UpdateHuaweiConnector,
  DeleteConnector
} from "../../services/SettingService";
import { IAndroidConnector, IDeleteConnector, IEmailConnector, IIosConnector, ISmsConnector } from "../../utils/models/ConnectorSettingsModel";
import { RootState } from "../Store";

export const getConnector: any = createAsyncThunk(
  "connector/getConnector",
  async (thunkAPI: any) => {
    try 
    {
      return await GetConnector();
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);


export const createEmailConnector: any = createAsyncThunk(
  "connector/createEmailConnector",
  async (dataEmail: IEmailConnector , thunkAPI: any) => {
    try 
    {
      return await CreateEmailConnector(dataEmail);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateEmailConnector: any = createAsyncThunk(
  "connector/updateEmailConnector",
  async (dataEmail: any , thunkAPI: any) => {
    try 
    {
      return await UpdateEmailConnector(dataEmail?.id, dataEmail?.data);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const createIosConnector: any = createAsyncThunk(
  "connector/createIosConnector",
  async (dataIos: IIosConnector , thunkAPI: any) => {
    try 
    {
      return await CreateIosConnector(dataIos);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateIosConnector: any = createAsyncThunk(
  "connector/updateIosConnector",
  async (dataIos: any , thunkAPI: any) => {
    try 
    {
      return await UpdateIosConnector(dataIos?.id, dataIos?.data);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);
export const createAndroidConnector: any = createAsyncThunk(
  "connector/createAndroidConnector",
  async (dataAndroid: IAndroidConnector , thunkAPI: any) => {
    try 
    {
      return await CreateAndroidConnector(dataAndroid);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateAndroidConnector: any = createAsyncThunk(
  "connector/updateAndroidConnector",
  async (dataAndroid: any , thunkAPI: any) => {
    try 
    {
      return await UpdateAndroidConnector(dataAndroid?.id, dataAndroid?.data);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const createSmsConnector: any = createAsyncThunk(
  "connector/createSmsConnector",
  async (dataAndroid: ISmsConnector , thunkAPI: any) => {
    try 
    {
      return await CreateSmsConnector(dataAndroid);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateSmsConnector: any = createAsyncThunk(
  "connector/updateSmsConnector",
  async (dataAndroid: any , thunkAPI: any) => {
    try 
    {
      return await UpdateSmsConnector(dataAndroid?.id, dataAndroid?.data);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const createHuweiConnector: any = createAsyncThunk(
  "connector/createHuweiConnector",
  async (dataHuawei: any , thunkAPI: any) => {
    try 
    {
      return await CreateHuaweiConnector(dataHuawei);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const updateHuweiConnector: any = createAsyncThunk(
  "connector/updateHuweiConnector",
  async (dataHuawei: any , thunkAPI: any) => {
    try 
    {
      return await UpdateHuaweiConnector(dataHuawei?.id, dataHuawei?.data);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getActiveConnector: any = createAsyncThunk(
  "connector/getActiveConnector",
  async (type: string, thunkAPI: any) => {
    try 
    {
      return await GetActiveConnector(type);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const deleteConnector: any = createAsyncThunk(
  "connector/deleteConnector",
  async (dataDelete: IDeleteConnector, thunkAPI: any) => {
    try 
    {
      return await DeleteConnector(dataDelete);
    }
    catch(err: any) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

const setCrudSettings = createSlice({
    name: 'SET_CRUD_CONSUMERS',
    initialState: {
        statusGetConnector: 'idl',
        statusCreateConnectorEmail: 'idl',
        statusUpdateConnectorEmail: 'idl',
        statusCreateConnectorSms: 'idl',
        statusUpdateConnectorSms: 'idl',
        statusCreateConnectorIos: 'idl',
        statusUpdateConnectorIos: 'idl',
        statusCreateConnectorAndroid: 'idl',
        statusUpdateConnectorAndroid: 'idl',
        statusGetActiveConnector: 'idl',
        statusForParent: 'idl',
        statusCreateConnectorHuawei: 'idl',
        statusUpdateConnectorHuawei: 'idl',
        statusDeleteConnector: 'idl'
    },
  
    reducers: {},
  
    extraReducers: {
      [getConnector.pending]: (state: any) => {
        state.statusGetConnector = 'loading';
      },
      [getConnector.fulfilled]: (state: any) => {
        state.statusGetConnector = 'success';
      },
      [getConnector.rejected]: (state: any) => {
        state.statusGetConnector = 'failed';
      },

      [createEmailConnector.pending]: (state: any) => {
        state.statusCreateConnectorEmail = 'loading';
        state.statusForParent = 'loading'
      },
      [createEmailConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorEmail = 'success';
        state.statusForParent = 'success'
      },
      [createEmailConnector.rejected]: (state: any) => {
        state.statusCreateConnectorEmail = 'failed';
        state.statusForParent = 'failed'
      },

      [updateEmailConnector.pending]: (state: any) => {
        state.statusUpdateConnectorEmail = 'loading';
        state.statusForParent = 'loading'
      },
      [updateEmailConnector.fulfilled]: (state: any) => {
        state.statusUpdateConnectorEmail = 'success';
        state.statusForParent = 'success'
      },
      [updateEmailConnector.rejected]: (state: any) => {
        state.statusUpdateConnectorEmail = 'failed';
        state.statusForParent = 'failed'
      },

      [createSmsConnector.pending]: (state: any) => {
        state.statusCreateConnectorSms = 'loading';
        state.statusForParent = 'loading'
      },
      [createSmsConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorSms = 'success';
        state.statusForParent = 'success'
      },
      [createSmsConnector.rejected]: (state: any) => {
        state.statusCreateConnectorSms = 'failed';
        state.statusForParent = 'failed'
      },

      [updateSmsConnector.pending]: (state: any) => {
        state.statusUpdateConnectorSms = 'loading';
        state.statusForParent = 'loading'
      },
      [updateSmsConnector.fulfilled]: (state: any) => {
        state.statusUpdateConnectorSms = 'success';
        state.statusForParent = 'success'
      },
      [updateSmsConnector.rejected]: (state: any) => {
        state.statusUpdateConnectorSms = 'failed';
        state.statusForParent = 'failed'
      },

      [createIosConnector.pending]: (state: any) => {
        state.statusCreateConnectorIos = 'loading';
        state.statusForParent = 'loading'
      },
      [createIosConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorIos = 'success';
        state.statusForParent = 'success'
      },
      [createIosConnector.rejected]: (state: any) => {
        state.statusCreateConnectorIos = 'failed';
        state.statusForParent = 'failed'
      },

      [updateIosConnector.pending]: (state: any) => {
        state.statusUpdateConnectorIos = 'loading';
        state.statusForParent = 'loading'
      },
      [updateIosConnector.fulfilled]: (state: any) => {
        state.statusUpdateConnectorIos = 'success';
        state.statusForParent = 'success'
      },
      [updateIosConnector.rejected]: (state: any) => {
        state.statusUpdateConnectorIos = 'failed';
        state.statusForParent = 'failed'
      },

      [createAndroidConnector.pending]: (state: any) => {
        state.statusCreateConnectorAndroid = 'loading';
        state.statusForParent = 'loading'
      },
      [createAndroidConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorAndroid = 'success';
        state.statusForParent = 'success'
      },
      [createAndroidConnector.rejected]: (state: any) => {
        state.statusCreateConnectorAndroid = 'failed';
        state.statusForParent = 'failed'
      },

      [updateAndroidConnector.pending]: (state: any) => {
        state.statusUpdateConnectorAndroid = 'loading';
        state.statusForParent = 'loading'
      },
      [updateAndroidConnector.fulfilled]: (state: any) => {
        state.statusUpdateConnectorAndroid = 'success';
        state.statusForParent = 'success'
      },
      [updateAndroidConnector.rejected]: (state: any) => {
        state.statusUpdateConnectorAndroid = 'failed';
        state.statusForParent = 'failed'
      },

      [getActiveConnector.pending]: (state: any) => {
        state.statusGetActiveConnector = 'loading';
        state.statusForParent = 'loading'
      },
      [getActiveConnector.fulfilled]: (state: any) => {
        state.statusGetActiveConnector = 'success';
        state.statusForParent = 'success'
      },
      [getActiveConnector.rejected]: (state: any) => {
        state.statusGetActiveConnector = 'failed';
        state.statusForParent = 'failed'
      },

      [createHuweiConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorHuawei = 'loading';
        state.statusForParent = 'loading'
      },
      [createHuweiConnector.fulfilled]: (state: any) => {
        state.statusCreateConnectorHuawei = 'success';
        state.statusForParent = 'success'
      },
      [createHuweiConnector.rejected]: (state: any) => {
        state.statusCreateConnectorHuawei = 'failed';
        state.statusForParent = 'failed'
      },

      [updateHuweiConnector.pending]: (state: any) => {
        state.statusUpdateConnectorHuawei = 'loading';
        state.statusForParent = 'loading'
      },
      [updateHuweiConnector.fulfilled]: (state: any) => {
        state.statusUpdateConnectorHuawei = 'success';
        state.statusForParent = 'success'
      },
      [updateHuweiConnector.rejected]: (state: any) => {
        state.statusUpdateConnectorHuawei = 'failed';
        state.statusForParent = 'failed'
      },

      [deleteConnector.pending]: (state: any) => {
        state.statusDeleteConnector = 'loading';
        state.statusForParent = 'loading'
      },
      [deleteConnector.fulfilled]: (state: any) => {
        state.statusDeleteConnector = 'success';
        state.statusForParent = 'success'
      },
      [deleteConnector.rejected]: (state: any) => {
        state.statusDeleteConnector = 'failed';
        state.statusForParent = 'failed'
      },
    },
  })

//EXPORT SELECTRO FOR CHILD
export const StateCrudConnector = (state: RootState) => state.setCrudSettings;

export default setCrudSettings.reducer