import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateConsumer, DeleteConsumer, GetConsumerById, GetConsumers, UpdateConsumer } from "../../services/ConsumersServices";
import { IUser } from "../../utils/models/UsersModels";
import { RootState } from "../Store";

export const getListConsumers: any = createAsyncThunk(
  "consumers/getListConsumers",
  async (data: any,thunkAPI: any) => {
    try 
    {
      return await GetConsumers(data?.params, data?.paramsHeader)
    }
    catch(err: any) {
        console.log('ERROR SLICE GET', err)
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const getConsumerById: any = createAsyncThunk(
    "consumers/getConsumerById",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await GetConsumerById(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const updateConsumer: any = createAsyncThunk(
    "consumers/updateConsumer",
    async (dataForm: {id: string, data: IUser}, thunkAPI: any) => {
      try 
      {
        return await UpdateConsumer(dataForm.id, dataForm.data)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const createConsumer: any = createAsyncThunk(
    "consumers/createConsumer",
    async (dataForm: any, thunkAPI: any) => {
      try 
      {
        return await CreateConsumer(dataForm)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );

  export const deleteConsumer: any = createAsyncThunk(
    "consumers/deleteConsumer",
    async (id: string, thunkAPI: any) => {
      try 
      {
        return await DeleteConsumer(id)
      }
      catch(err: any) {
          console.log('ERROR SLICE GET BY ID', err)
        return thunkAPI.rejectWithValue({ error: err.message });
      }
    }
  );


const setCrudConsumers = createSlice({
    name: 'SET_CRUD_CONSUMERS',
    initialState: {
        statusGet: 'idl',
        statusGetById: 'idl',
        statusCreate: 'idl',
        statusUpdate: 'idl',
        statusDelete: 'idl',
    },
  
    reducers: {},
  
    extraReducers: {
      [getListConsumers.pending]: (state: any) => {
        state.statusGet = 'loading';
      },
      [getListConsumers.fulfilled]: (state: any) => {
        state.statusGet = 'success';
      },
      [getListConsumers.rejected]: (state: any) => {
        state.statusGet = 'failed';
      },

      [getConsumerById.pending]: (state: any) => {
        state.statusGetById = 'loading';
      },
      [getConsumerById.fulfilled]: (state: any) => {
        state.statusGetById = 'success';
      },
      [getConsumerById.rejected]: (state: any) => {
        state.statusGetById = 'failed';
      },

      [updateConsumer.pending]: (state: any) => {
        state.statusUpdate = 'loading';
      },
      [updateConsumer.fulfilled]: (state: any) => {
        state.statusUpdate = 'success';
      },
      [updateConsumer.rejected]: (state: any) => {
        state.statusUpdate = 'failed';
      },

      [createConsumer.pending]: (state: any) => {
        state.statusCreate = 'loading';
      },
      [createConsumer.fulfilled]: (state: any) => {
        state.statusCreate = 'success';
      },
      [createConsumer.rejected]: (state: any) => {
        state.statusCreate = 'failed';
      },

      [deleteConsumer.pending]: (state: any) => {
        state.statusDelete = 'loading';
      },
      [deleteConsumer.fulfilled]: (state: any) => {
        state.statusDelete = 'success';
      },
      [deleteConsumer.rejected]: (state: any) => {
        state.statusDelete = 'failed';
      },

    },
  })

//EXPORT SELECTRO FOR CHILD
export const StateCrudConsumers = (state: RootState) => state.setCrudConsumers;

export default setCrudConsumers.reducer