import axios from "axios";
import { axiosInterceptor } from "../interceptors/AppInterceptor";
import { IUser } from "../utils/models/UsersModels";

export async function GetUsers(params: any, paramsHeader: any) {
    const queryString = params ? `?fullText=${params}` : '' 
    return axiosInterceptor.get(`/api/v1/users${queryString}`, {headers: {...paramsHeader}})
}

export async function GetUserById(id: string) {
    return axiosInterceptor.get(`/api/v1/users/${id}`)
}

export async function UpdateUser(id: string, dataForm: IUser) {
    return axiosInterceptor.patch(`/api/v1/users/${id}`, dataForm)
}

export async function CreateUser(dataForm: IUser) {
    return axiosInterceptor.post(`/api/v1/users`, dataForm)
}

export async function DeleteUser(id: string) {
    return axiosInterceptor.delete(`/api/v1/users/${id}`)
}

export async function ImpersonateUser(body: any, token?: string) {
    let data: any = {};
    if (body?.email) data.email = body?.email;
    else data.consumer = body?.consumer;

    return token
        ? axios.post(`/api/v1/auth/impersonate`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        : axiosInterceptor.post(`/api/v1/auth/impersonate`, data)
}