import { queryStringHelper } from "../helpers/queryString";
import { axiosInterceptor } from "../interceptors/AppInterceptor";

export async function GetTemplate(params: any, paramsHeader: any) {
    let queryString: string = queryStringHelper(params);
    if (Object.keys(params).length === 1) {
        queryString = params ? `?fullText=${params}` : '' 
    }
    return axiosInterceptor.get(`/api/v1/templates${queryString}`, {headers: {...paramsHeader}})
}

export async function GetTemplateById(id: string) {
    return axiosInterceptor.get(`/api/v1/templates/${id}`)
}

export async function UpdateTemplate(id: string, dataForm: any) {
    return axiosInterceptor.patch(`/api/v1/templates/${id}`, dataForm)
}

export async function CreateTemplate(dataForm: any) {
    return axiosInterceptor.post(`/api/v1/templates`, dataForm)
}

export async function DeleteTemplate(id: string) {
    return axiosInterceptor.delete(`/api/v1/templates/${id}`)
}