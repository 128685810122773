import { queryStringHelper } from "../helpers/queryString";
import { axiosInterceptor } from "../interceptors/AppInterceptor";
import { IMessage } from "../utils/models/MessagesModels";

export async function GetMessages(params: any, paramsHeader: any) {

    let queryString: string = '';

    if (params.fullText && params.channel && params.channel !== 'all') {
        queryString = `?channel=${params.channel}&fullText=${params.fullText}`
    }

    if (params.fullText && (!params.channel || params.channel === 'all')) {
        queryString = `?fullText=${params.fullText}`
    }

    if (params.channel && params.channel !== 'all' && !params.fullText) {
        queryString = `?channel=${params.channel}`
    }

    return axiosInterceptor.get(`/api/v1/messages${queryString}`, { headers: { ...paramsHeader } })
}

export async function GetMessageById(id: string) {
    return axiosInterceptor.get(`/api/v1/messages/${id}`)
}

export async function UpdateMessage(id: string, dataForm: IMessage) {
    return axiosInterceptor.patch(`/api/v1/messages/${id}`, dataForm)
}

export async function CreateMessage(dataForm: IMessage) {
    return axiosInterceptor.post(`/api/v1/messages`, dataForm)
}

export async function DeleteMessage(id: string) {
    return axiosInterceptor.delete(`/api/v1/messages/${id}`)
}

export async function SendMessage(channel: string, dataForm: any) {
    const mapper: any = { MOBILEPUSH: 'mobile', SMS: 'sms', MAIL: 'mail' }
    return axiosInterceptor.post(`/api/v1/messages/send/${mapper[channel]}`, dataForm)
}

export async function GetStatsMessages(params: any, paramsHeader: any) {
    let queryString: string = queryStringHelper(params);
    return axiosInterceptor.get(`/api/v1/messages/statistics${queryString}`, { headers: { ...paramsHeader } })
}
